import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Location } from '@angular/common';
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, map, of, switchMap, from, tap } from "rxjs";
import { MongoDbService } from "../../services/api/mongodb.service";
import { ContentfulService } from "../../services/cms/contentful.service";
import { addCart, addCartFailure, addCartSuccess, getCarts, getCartsFailure, getCartsSuccess, getCatalogProducts, getCatalogProductsFailure, getCatalogProductsSuccess, getContentfulBrands, getContentfulBrandsFailure, getContentfulBrandsSuccess, getContentfulCategories, getContentfulCategoriesFailure, getContentfulCategoriesSuccess, getContentfulDesignToolProducts, getContentfulDesignToolProductsFailure, getContentfulDesignToolProductsSuccess, getContentfulFeaturedCollections, getContentfulFeaturedCollectionsFailure, getContentfulFeaturedCollectionsSuccess, getContentfulHero, getContentfulHeroFailure, getContentfulHeroSuccess, getContentfulProducts, getContentfulProductsFailure, getContentfulProductsSuccess, getContentfulSubcategories, getContentfulSubcategoriesFailure, getContentfulSubcategoriesSuccess, getContentfulBanner, getProductVariant, getProductVariantColor, getProductVariantColorFailure, getProductVariantColorSuccess, getProductVariantFailure, getProductVariantSuccess, updatePricingProductVariantColor, updatePricingProductVariantColorFailure, updatePricingProductVariantColorSuccess, getCarouselHeader, getCarouselHeaderSuccess, getCarouselHeaderFailure, getCategoryGallery, getCategoryGallerySuccess, getCategoryGalleryFailure, getKeyFeatures, getKeyFeaturesSuccess, getKeyFeaturesFailure, getCallToAction, getCallToActionSuccess, getCallToActionFailure, getFAQ, getFAQSuccess, getFAQFailure } from "./catalog.actions";
import { CatalogDesignService } from "../../services/catalog/catalog-design.service";
import { VariantColors } from "../../models/designTool";
import { Store } from "@ngrx/store";
import * as CatalogActions from '@core/state/catalog/catalog.actions';
import { SessionService } from "@core/services/session/session.service";

@Injectable()
export class CatalogEffects {
    constructor(
        private actions$: Actions,
        private contentfulService: ContentfulService,
        private mongoService: MongoDbService,
        private router: Router,
        private location: Location,
        private catalogDesign:CatalogDesignService,
        private store: Store,
        protected session:SessionService,
    ) { }

    getContentfulHero$ = createEffect(() =>
        this.actions$.pipe(
            ofType(getContentfulHero),
            switchMap((action) => this.contentfulService.getHero()
                .pipe(
                    map((heroResponse: any) =>
                        getContentfulHeroSuccess({ heroResponse })
                    ),
                    catchError((error) => of(getContentfulHeroFailure({ error })))
                )
            )
        )
    );

    getProductVariant$ = createEffect(() =>
    this.actions$.pipe(
        ofType(getProductVariant),
        switchMap((action) => this.catalogDesign.getVariantData(action.brand, action.style)
            .pipe(
                map((variantResponse: any) =>
                getProductVariantSuccess({ variantResponse })
                ),
                catchError((error) => of(getProductVariantFailure({ error })))
            )
        )
    )
);

getProductVariantColor$ = createEffect(() =>
this.actions$.pipe(
    ofType(getProductVariantColor),
    switchMap((action) => this.catalogDesign.getVariantColorData(action.brand, action.style, action.color, action.clientId, action.positions, action.qty)
        .pipe(
            map((variantResponse: VariantColors[]) => {
            return getProductVariantColorSuccess({ variantResponse })
            }),
            catchError((error) => of(getProductVariantColorFailure({ error })))
        )
    )
)
);

    updatePricingProductVariantColor$ = createEffect(() =>
    this.actions$.pipe(
        ofType(updatePricingProductVariantColor),
        switchMap((action) => this.catalogDesign.getVariantColorData(action.brand, action.style, action.color, action.clientId, action.positions, action.qty)
            .pipe(
                map((variantResponse: VariantColors[]) => {

                return updatePricingProductVariantColorSuccess({ variantResponse })
                }),
                catchError((error) => of(updatePricingProductVariantColorFailure({ error })))
            )
        )
    )
    );


    getContentfulCategories$ = createEffect(() =>
        this.actions$.pipe(
            ofType(getContentfulCategories),
            switchMap((action) => this.contentfulService.getPopularCategoriesHome()
                .pipe(
                    map((categoryResponse: any) =>
                        getContentfulCategoriesSuccess({ categoryResponse })
                    ),
                    catchError((error) => of(getContentfulCategoriesFailure({ error })))
                )
            )
        )
    );

    getContentfulDesignToolProducts$ = createEffect(() =>
    this.actions$.pipe(
        ofType(getContentfulDesignToolProducts),
        switchMap((action) => this.contentfulService.getDesignToolHome()
            .pipe(
                map((designToolResponse: any) =>
                    getContentfulDesignToolProductsSuccess({ designToolResponse })
                ),
                catchError((error) => of(getContentfulDesignToolProductsFailure({ error })))
            )
        )
    )
);

    getContentfulProducts$ = createEffect(() =>
        this.actions$.pipe(
            ofType(getContentfulProducts),
            switchMap((action) => this.contentfulService.getPopularProductsHome()
                .pipe(
                    map((productResponse: any) =>
                        getContentfulProductsSuccess({ productResponse })
                    ),
                    catchError((error) => of(getContentfulProductsFailure({ error })))
                )
            )
        )
    );

        getContentfulFeaturedCollections$ = createEffect(() =>
        this.actions$.pipe(
            ofType(getContentfulFeaturedCollections),
            switchMap((action) => this.contentfulService.getFeaturedCollections()
                .pipe(
                    map((featureCollectionResponse: any) =>
                        getContentfulFeaturedCollectionsSuccess({ featureCollectionResponse })
                    ),
                    catchError((error) => of(getContentfulFeaturedCollectionsFailure({ error })))
                )
            )
        )
    );

    getContentfulBrands$ = createEffect(() =>
        this.actions$.pipe(
            ofType(getContentfulBrands),
            switchMap((action) => this.contentfulService.getTopBrands()
                .pipe(
                    map((brandResponse: any) =>
                        getContentfulBrandsSuccess({ brandResponse })
                    ),
                    catchError((error) => of(getContentfulBrandsFailure({ error })))
                )
            )
        )
    );

    getContentfulSubcategories$ = createEffect(() =>
        this.actions$.pipe(
            ofType(getContentfulSubcategories),
            switchMap((action) => this.contentfulService.getSubcategory()
                .pipe(
                    map((subcategoriesResponse: any) =>
                        getContentfulSubcategoriesSuccess({ subcategoriesResponse })
                    ),
                    catchError((error) => of(getContentfulSubcategoriesFailure({ error })))
                )
            )
        )
    );

    getContefulBanner$ = createEffect(() =>
        this.actions$.pipe(
            ofType( getContentfulBanner),
            switchMap((action) => this.contentfulService.getBanner()
            .pipe(
                map((bannerResponse: any) =>
                    CatalogActions. getContentfulBannerSuccess({ bannerResponse })),
                catchError((error) => of(CatalogActions. getContentfulBannerFailure({ error })))
            ))
        )
    );

    getCatalogProducts$ = createEffect(() =>
    this.actions$.pipe(
        ofType(getCatalogProducts),
        switchMap((action) =>
            this.mongoService.searchProducts(
                action.searchParam,
                action.currentPage,
                action.sort
            ).pipe(
                tap(() => {
                    const currentUrl = this.location.path();
                    const searchParams = action.searchParam;

                    // Construct URL parameters
                    const urlParams = searchParams.map(param => {
                        const key = Object.keys(param)[0]; // Get the key dynamically
                        const value = param[key]; // Get the value associated with the key
                        return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
                    });

                    const newUrl = `/catalog/all?${urlParams.join('&')}`;
                    if (currentUrl !== newUrl) {
                        this.location.go(newUrl); // Update the URL without reloading the page
                    }
                }),
                map((productsResponse: any) =>
                    getCatalogProductsSuccess({ productsResponse })
                ),
                catchError((error) => of(getCatalogProductsFailure({ error })))
            )
        )
    )
);

// get Carts from Mongo by clientId
getCarts$ = createEffect(() =>
this.actions$.pipe(
    ofType(getCarts),
    switchMap((action) =>
        this.mongoService.getCarts(
            action.clientId
        ).pipe(
            map((cartsResponse: any) =>
                getCartsSuccess({ cartsResponse })
            ),
            catchError((error) => of(getCartsFailure({ error })))
        )
    )
)
);

// add Cart from Mongo by clientId
    addCart$ = createEffect(() =>
    this.actions$.pipe(
    ofType(addCart),
    switchMap((action) =>
        from(this.mongoService.addCart(action.cart)).pipe( // Convert to observable
        map((cartResponse: any) =>
            addCartSuccess({ cartResponse })
        ),
        catchError((error) => of(addCartFailure({ error }))
        )
        )
    ),
    tap(() => {
        // window.location.reload(); // Trigger page refresh after a successful cart addition
        this.store.dispatch(CatalogActions.getCarts({
            clientId: this.session.getActiveClient().id
        }));
    })
    )
    );

    getCarouselHeader$ = createEffect(() =>
        this.actions$.pipe(
            ofType(getCarouselHeader),
            switchMap((action) => this.contentfulService.getCarouselHeader()
                .pipe(
                    map((carouselHeaderResponse: any) =>
                        getCarouselHeaderSuccess({ carouselHeaderResponse })
                    ),
                    catchError((error) => of(getCarouselHeaderFailure({ error })))
                )
            )
        )
    );

    getCategoryGallery$ = createEffect(() =>
        this.actions$.pipe(
            ofType(getCategoryGallery),
            switchMap((action) => this.contentfulService.getCategoryGallery()
                .pipe(
                    map((categoryGalleryResponse: any) =>
                        getCategoryGallerySuccess({ categoryGalleryResponse })
                    ),
                    catchError((error) => of(getCategoryGalleryFailure({ error })))
                )
            )
        )
    );

    getKeyFeatures$ = createEffect(() =>
        this.actions$.pipe(
            ofType(getKeyFeatures),
            switchMap((action) => this.contentfulService.getKeyFeatures()
                .pipe(
                    map((keyFeaturesResponse: any) =>
                        getKeyFeaturesSuccess({ keyFeaturesResponse })
                    ),
                    catchError((error) => of(getKeyFeaturesFailure({ error })))
                )
            )
        )
    );

    getCallToAction$ = createEffect(() =>
        this.actions$.pipe(
            ofType(getCallToAction),
            switchMap((action) => this.contentfulService.getCallToAction()
                .pipe(
                    map((callToActionResponse: any) =>
                        getCallToActionSuccess({ callToActionResponse })
                    ),
                    catchError((error) => of(getCallToActionFailure({ error })))
                )
            )
        )
    );

    getFAQ$ = createEffect(() =>
        this.actions$.pipe(
            ofType(getFAQ),
            switchMap((action) => this.contentfulService.getFAQ()
                .pipe(
                    map((FAQResponse: any) =>
                        getFAQSuccess({ FAQResponse })
                    ),
                    catchError((error) => of(getFAQFailure({ error })))
                )
            )
        )
    );
}
