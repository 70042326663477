import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginBaseComponent } from './features/login/login-base/login-base.component';
import { BaseComponent } from './core/base/base.component';
import { AboutStokkupComponent } from './features/about/about-stokkup/about-stokkup.component';
import { PrivacyPolicyComponent } from './features/about/privacy-policy/privacy-policy.component';
import { SupportComponent } from './features/about/support/support.component';
import { TermsOfServiceComponent } from './features/about/terms-of-service/terms-of-service.component';
import { BaseNavResolver } from './core/resolver/base-nav.resolver';
import { SelectedDecoratorGuard } from '@core/guards/selected-decorator.guard';
import { AuthGuard } from '@core/guards/auth.guard';
import { SignupComponent } from '@features/signup/signup.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/catalog',
    pathMatch: 'full'
  },
  { 
    path: 'login',
    component: LoginBaseComponent,
    resolve: { baseResolver: BaseNavResolver },
    loadChildren: () => import('./features/login/login.module').then(m => m.LoginModule),
  },
  { 
    path: 'signup',
    component: SignupComponent
  },
  { 
    path: 'orders',
    component: BaseComponent,
    resolve: { baseResolver: BaseNavResolver },
    loadChildren: () => import('./features/orders/orders.module').then(m => m.OrdersModule),
  },
  { 
    path: 'floor-stock',
    component: BaseComponent,
    resolve: { baseResolver: BaseNavResolver },
    loadChildren: () => import('./features/floor-stock/floor-stock.module').then(m => m.FloorStockModule),
    canActivate: [AuthGuard, SelectedDecoratorGuard]
  },
  { 
    path: 'catalog',
    component: BaseComponent,
    resolve: { baseResolver: BaseNavResolver },
    loadChildren: () => import('./features/catalog/catalog.module').then(m => m.CatalogModule),
    canActivate: [SelectedDecoratorGuard]
  },
  { 
    path: 'dashboard',
    component: BaseComponent,
    resolve: { baseResolver: BaseNavResolver },
    loadChildren: () => import('./features/dashboard/dashboard.module').then(m => m.DashboardModule),
    canActivate: [AuthGuard, SelectedDecoratorGuard]
  },
  { 
    path: 'cart',
    component: BaseComponent,
    resolve: { baseResolver: BaseNavResolver },
    loadChildren: () => import('./features/cart/cart.module').then(m => m.CartModule),
    canActivate: [AuthGuard, SelectedDecoratorGuard]
  },
  {
    path: 'about',
    component: BaseComponent,
    resolve: { baseResolver: BaseNavResolver },
    children: [
      { path: '', component: AboutStokkupComponent },
      { path: 'privacy-policy', component: PrivacyPolicyComponent },
      { path: 'support', component: SupportComponent },
      { path: 'terms-of-service', component: TermsOfServiceComponent },
    ]
  },
  {
    path: '**',
    redirectTo: 'catalog'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
