import { createReducer, on } from "@ngrx/store";
import { IOrder, IOrderCounts, IOrderDetail, OrderData } from "../../models/order";
import { createOrderEstimateFailure, createOrderEstimateSuccess, getAffiliateOrderInfoFailure, getAffiliateOrderInfoSuccess, getImagesForDesignFailure, getImagesForDesignSuccess, getOrderDetailFailure, getOrderDetailSuccess, getOrderDetailV2Failure, getOrderDetailV2Success, getOrderInfoAffiliateHeadersFailure, getOrderInfoAffiliateHeadersSuccess, getOrderInfoFailure, getOrderInfoHeadersFailure, getOrderInfoHeadersSuccess, getOrderInfoSuccess } from "./order.actions";

export interface State {
    orders: IOrder,
    headers: IOrderCounts
    ordersDetail: [IOrderDetail],
    ordersDetailV2: [],
    designImages?: any;
    orderError?: string
    headersError ?: string,
}

export const initialState: State = {
    orders: null,
    ordersDetail: null,
    ordersDetailV2: null,
    headers: null
}

const _orderReducer = createReducer (
    initialState,
    on(getOrderInfoSuccess, (state, {orderResponse}) => {

        return {
            ...state,
            orders: orderResponse,
        }
    }),
    on(getOrderInfoFailure, (state, {error}) => {
        return {
            ...state,
            orders: null,
            orderError: error
        }
    }),
    on(getAffiliateOrderInfoSuccess, (state, {affiliateResponse}) => {

        return {
            ...state,
            orders: affiliateResponse
        }
    }),
    on(getAffiliateOrderInfoFailure, (state, {error}) => {
        return {
            ...state,
            orders: null,
            orderError: error
        }
    }),
    on(getOrderDetailSuccess, (state, {orderDetailResponse}) => {

        return {
            ...state,
            ordersDetail: orderDetailResponse
        }
    }),
    on(getOrderDetailFailure, (state, {error}) => {
        return {
            ...state,
            ordersDetail: null,
            orderError: error
        }
    }),
    on(getOrderDetailV2Success, (state, {response}) => {
        return {
            ...state,
            ordersDetailV2: response
        }
    }),
    on(getOrderDetailV2Failure, (state, {error}) => {
        return {
            ...state,
            ordersDetailV2: null,
            orderError: error
        }
    }),
    on(getImagesForDesignSuccess, (state, {response}) => {

        return {
            ...state,
            designImages: response
        }
    }),
    on(getImagesForDesignFailure, (state, {error}) => {
        return {
            ...state,
            designImages: null,
            // orderError: error
        }
    }),
    on(createOrderEstimateSuccess, (state, {dataResponse}) => {
        return {
            ...state
        }
    }),
    on(createOrderEstimateFailure, (state, {error}) => {
        return {
            ...state,
            orderError: error
        }
    }),
    on(getOrderInfoHeadersSuccess, (state, { orderResponse }) => {
        return {
            ...state,
            headers: orderResponse
        }
    }),
    on(getOrderInfoHeadersFailure, (state, {error}) => {
        return {
            ...state,
            headersError: error
        }
    }),
    on(getOrderInfoAffiliateHeadersSuccess, (state, { orderResponse }) => {
        return {
            ...state,
            headers: orderResponse
        }
    }),
    on(getOrderInfoAffiliateHeadersFailure, (state, {error}) => {
        return {
            ...state,
            headersError: error
        }
    }),
)

export function orderReducer(state, action) {
    return _orderReducer(state,action)
}