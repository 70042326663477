import { Entity } from "@decahedron/entity";


export class PlaceOrderResponse extends Entity {
    public success: boolean = false;
    public message: string = '';
    public code: string = '';
    public data: PlaceOrderData = null;
  }
  
  export class PlaceOrderData extends Entity {
    public captureResponse: CaptureResponse = null;
    public orderNumber: string = null;
  }
  
  export class AuthPaymentResponse extends Entity { // fix this schema
    public respstat: string = '';
    public resptext: string = '';
    // Add additional fields if needed
  }

  export class CaptureResponse extends Entity {
    public account: string = '';
    public amount: string = '';
    public authcode: string = '';
    public batchid: string = '';
    public merchid: string = '';
    public respcode: string = '';
    public respproc: string = '';
    public respstat: string = '';
    public resptext: string = '';
    public retref: string = '';
    public setlstat: string = '';   
    public token: string = ''; 
    // Add fields returned by `capturePaymentCardConnect`
  }
  