import { Injectable } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { firstValueFrom } from 'rxjs';
import { SessionService } from '../session/session.service';

declare global {
  interface Window {
    dataLayer: any[];
  }
}

@Injectable({
  providedIn: 'root'
})
export class DataLayerService {

  private _userId: string | null = null;
  private _clientId: number | null = null;


  constructor(
    private auth: AuthService,
    private sessionService: SessionService
  ){ 
    this.initializeUserId();
    this.initializeClientId();
  }

  private async initializeUserId() {
    try {
      const user = await firstValueFrom(this.auth.user$);
      this._userId = user?.id || null;
    } catch (error) {
      console.error('Error retrieving user ID:', error);
    }
  }

  private initializeClientId() {
    try {
      const client = this.sessionService.getActiveClient();
      this._clientId = client?.id || null;
    } catch (error) {
      console.error('Error retrieving client ID:', error);
    }
  }

  get userId(): string | null {
    return this._userId;
  }

  get clientId(): number | null {
    return this._clientId;
  }

  private pingHome(obj) {
    window.dataLayer = window.dataLayer || [];
    if(obj)  window.dataLayer.push(obj);
  }

  // add new method
  conversionShareASales(event, orderId, subtotal){
    const hit = {
      event: event,
      orderId: orderId,
      subtotal: subtotal
    }
    this.pingHome(hit);
  }

  logPageView(url) {
    const hit = {
      event: 'content-view',
      pageName: url
    };
    this.pingHome(hit);  
  }

  logProductView(brand: string, style: string, data: any){
    const hit = {
      event: 'view_item',
      clientId: this.clientId,
      userId: this.userId,
      ecommerce: {
        items: [{
            item_name: data.title,
            item_id: data._id,
            item_category: brand, 
            item_category_2: style, 
            item_category_3: this.toCamelCase(data.colorName)
        }]
      }
    };
    this.pingHome(hit);  
  }

  logAddToCart(data: any){
    const hit = {
      event: 'add_to_cart',
      clientId: this.clientId,
      userId: this.userId,
      ecommerce: {
        currency: 'USD',
        cartId: data.cartId,
        items: this.formatDesigns(data)
      }
    }

    this.pingHome(hit);
  }

  logBeginCheckout(data: any){
    const hit = {
      event: 'begin_checkout',
      clientId: this.clientId,
      userId: this.userId,
      ecommerce: {
        currency: 'USD',
        cartId: data.cartId,
        items: this.formatDesigns(data)
      }
    }

    this.pingHome(hit);
  }

  logPurchase(data: any, orderId: number, subTotal: number, shippingTotal: number, taxTotal: number, promoCode: string, rushFee: number){
    const hit = {
      event: 'purchase',
      clientId: this.clientId,
      userId: this.userId,
      ecommerce: {
        transaction_id: orderId,
        value: subTotal,
        tax: taxTotal,
        shipping: shippingTotal,
        currency: 'USD',
        coupon: promoCode ?? '',
        cartId: data.cartId,
        items: this.formatDesigns(data),
        rush_order_fee: rushFee
      }
    }

    this.pingHome(hit);

  }

  logProductDesign(brand, style, data){
    const hit = {};

    this.pingHome(hit);
  }

  logEvent(event,category,action,label, data, viewport = 'desktop') {
    const hit = {
        event:event,
        category:category,
        action:action,
        label: label,
        product: data,
        viewport: viewport
    }
    this.pingHome(hit);
  }

  logOrderConversionEvent(event,category,action,label, data, viewport = 'desktop') {
    const hit = {
        event:event,
        category:category,
        action:action,
        label: label,
        order: data,
        viewport: viewport
    }
    this.pingHome(hit);
  }

  logCustomDimensionTest(value) {
    const hit = {
        event:'custom-dimension',
        value:value
    }
    this.pingHome(hit);
  }

  // Helper method camel case: 
  toCamelCase(str: string): string {
    return str
        .toLowerCase()
        .replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) => {
            return index === 0 ? word.toUpperCase() : word.toUpperCase();
        })
        .replace(/\s+/g, ' ');
  }

  formatDesigns(data: any) {
    return data.designs.map(design => {
      const { title, brand, style, variants } = design.products;
      const item_id = design._id;
      const color = variants[0].color;

      const price = variants.reduce((total, variant) => total + (variant.price * variant.qty), 0);
      const qty = variants.reduce((total, variant) => total + variant.qty, 0);

      return {
        item_name: title,
        item_id: item_id,
        item_category: brand,
        item_category_2: style,
        item_category_3: this.toCamelCase(color),
        price: price,
        qty: qty
      };
    });
  }
}
