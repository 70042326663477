import { createReducer, on } from "@ngrx/store"
import { IFilters, IMongoProduct } from "../../models/catalog";
import { getContentfulBrandsFailure, getContentfulBrandsSuccess, getContentfulCategoriesFailure, getContentfulCategoriesSuccess, getContentfulHeroFailure, getContentfulHeroSuccess, getContentfulProductsFailure, getContentfulProductsSuccess, getContentfulSubcategoriesFailure, getContentfulSubcategoriesSuccess, getCatalogProductsSuccess, getCatalogProductsFailure, updateCatalogQueryFailure, updateCatalogQuery, updateCatalogCategoriesNav, updateCatalogCategoriesNavFailure, getProductVariantSuccess, getProductVariantFailure, getProductVariantColorSuccess, getProductVariantColorFailure, updateCatalogBrandsNav, updateCatalogBrandsNavFailure, getCartsSuccess, getCartsFailure, SetSearchTermAction, updatePricingProductVariantColorSuccess, updatePricingProductVariantColorFailure, getContentfulDesignToolProductsSuccess, getContentfulDesignToolProductsFailure, getContentfulFeaturedCollectionsSuccess, getContentfulFeaturedCollectionsFailure,  getContentfulBannerSuccess,  getContentfulBannerFailure, getCarouselHeaderSuccess, getCarouselHeaderFailure, getCategoryGallerySuccess, getCategoryGalleryFailure, getKeyFeaturesSuccess, getKeyFeaturesFailure, getCallToActionSuccess, getCallToActionFailure, getFAQSuccess, getFAQFailure  } from "./catalog.actions"
import { VariantColors } from "../../models/designTool";

export interface State {
    contentfulHero: any,
    contentfulHeroError?: string

    contentfulCategories: any,
    contentfulCategoriesError?: string

    contentfulDesignToolProducts: any,
    contentfulDesignToolProductsError?: string

    contentfulProducts: any,
    contentfulProductsError?: string,

    contentfulFeaturedCollections: any,
    contentfulFeaturedCollectionsError?: string,

    contentfulBrands: any,
    contentfulBrandsError?: string,

    contentfulSubcategories: any,
    contentfulSubcategoriesError?: string,

    contentfulBanner: any,
    contentfulBannerError?: string,

    products: IMongoProduct[],
    productsError?: string,

    filters: IFilters; // Added nested state for name filters
    filtersError?: string,

    totalProductsCount: number,
    totalProductsCountError?: string,

    totalPages: number,
    totalPagesError?: string,

    currentPage: number,
    currentPageError?: string,

    searchTerm: string

    productVariant: any,
    productVariantError?: string

    productVariantColor: VariantColors[],
    productVariantColorError?: string

    productVariantColorPricing: VariantColors[]

    carts: any[]
    cartsError?: string;

    carouselHeader: any,
    carouselHeaderError?: string

    categoryGallery: any,
    categoryGalleryError?: string

    keyFeatures: any,

    callToAction: any,

    FAQ: any;

}

export const initialState: State = {
    contentfulHero: null,
    contentfulCategories: null,
    contentfulDesignToolProducts: null,
    contentfulProducts: null,
    contentfulFeaturedCollections: null,
    contentfulBrands: null,
    contentfulSubcategories: null,
    contentfulBanner: null,
    products: [],
    filters: {
        productSelectedFilters: null,
        productSelectedFiltersPrevious: null,
        productSort: null,
        productSortPrevious: null,
        productBrands: null,
        productCategories: null,
        productFiltersResponse: null,
      },
    totalProductsCount: null,
    totalPages: null,
    currentPage: null,
    searchTerm: null,
    productVariant: null,
    productVariantColor: [],
    productVariantColorPricing: [],
    carts: [],
    carouselHeader: null,
    categoryGallery: null,
    keyFeatures: null,
    callToAction: null,
    FAQ: null
}

const _catalogReducer = createReducer(
    initialState,
    on(getContentfulHeroSuccess, (state, { heroResponse }) => {
        return {
            ...state,
            contentfulHero: heroResponse
        }
    }),
    on(getContentfulHeroFailure, (state, { error }) => {
        return {
            ...state,
            contentfulHero: null,
            contentfulHeroError: error
        }
    }),
    on(getContentfulCategoriesSuccess, (state, { categoryResponse }) => {
        return {
            ...state,
            contentfulCategories: categoryResponse
        }
    }),
    on(getContentfulCategoriesFailure, (state, { error }) => {
        return {
            ...state,
            contentfulCategories: null,
            contentfulCategoriesError: error
        }
    }),
    on(getContentfulDesignToolProductsSuccess, (state, { designToolResponse }) => {
        return {
            ...state,
            contentfulDesignToolProducts: designToolResponse
        }
    }),
    on(getContentfulDesignToolProductsFailure, (state, { error }) => {
        return {
            ...state,
            contentfulDesignToolProducts: null,
            contentfulDesignToolProductsError: error
        }
    }),
    on(getContentfulProductsSuccess, (state, { productResponse }) => {
        return {
            ...state,
            contentfulProducts: productResponse
        }
    }),
    on(getContentfulProductsFailure, (state, { error }) => {
        return {
            ...state,
            contentfulProducts: null,
            contentfulProductsError: error
        }
    }),
    on(getContentfulFeaturedCollectionsSuccess, (state, {featureCollectionResponse}) => {
        return {
            ...state,
            contentfulFeaturedCollections: featureCollectionResponse
        }
    }),
    on(getContentfulFeaturedCollectionsFailure, (state, {error}) => {
        return {
            ...state,
            contentfulFeaturedCollections: null,
            contentfulFeaturedCollectionsError: error
        }
    }),
    on(getContentfulBrandsSuccess, (state, {brandResponse}) => {
        return {
            ...state,
            contentfulBrands: brandResponse
        }
    }),
    on(getContentfulBrandsFailure, (state, {error}) => {
        return {
            ...state,
            contentfulBrands: null,
            contentfulBrandsError: error
        }
    }),
    on(getContentfulSubcategoriesSuccess, (state, { subcategoriesResponse }) => {
        return {
            ...state,
            contentfulSubcategories: subcategoriesResponse
        }
    }),
    on(getContentfulSubcategoriesFailure, (state, { error }) => {
        return {
            ...state,
            contentfulSubcategories: null,
            contentfulSubcategoriesError: error
        }
    }),
    on( getContentfulBannerSuccess, (state, { bannerResponse }) => {
        return {
            ...state,
            contentfulBanner: bannerResponse
        }
    }),
    on( getContentfulBannerFailure, (state, { error }) => {
        return {
            ...state,
            contentfulBanner: null,
            contentfulBannerError: error
        }
    }),
    on(getCatalogProductsSuccess, (state, { productsResponse }) => {
        let updateProducts;

        if( !state.filters.productSelectedFiltersPrevious || JSON.stringify(state.filters.productSelectedFiltersPrevious) == JSON.stringify(state.filters.productSelectedFilters) && state.currentPage != 1 && state.filters.productSort == state.filters.productSortPrevious) {
            updateProducts = [...state.products, ...productsResponse.products]
        } else {
            updateProducts = productsResponse.products
        }
        
        return {
            ...state,
            products: updateProducts, // Merge the existing products with productsResponse.products
            filters: {
                ...state.filters,
                productFiltersResponse: productsResponse.filters,
              },
            totalProductsCount: productsResponse.totalCount,
            totalPages: productsResponse.totalPages,
        }
    }),
    on(getCatalogProductsFailure, (state, { error }) => {
        return {
            ...state,
            products: null,
            productsError: error,
            filters: {
                ...state.filters,
                productFiltersResponse: null,
                filtersError: error
              },
            filtersError: error,
            totalProductsCount: null,
            totalProductsCountError: error,
            totalPages: null,
            totalPagesError: error,
        }
    }),
    on(updateCatalogQuery, (state, { queryUpdate, currentPage, sort }) => {
        return {
            ...state,
            filters: {
                ...state.filters,
                productSelectedFilters: queryUpdate,
                productSelectedFiltersPrevious: state.filters.productSelectedFilters,
                productSort: sort,
                productSortPrevious: state.filters.productSort
              },
            currentPage: currentPage
        }
    }),
    on(updateCatalogQueryFailure, (state, { error }) => {
        return {
            ...state,
            filters: {
                ...state.filters,
                productSelectedFilters: null,
                productsSelectedFiltersPrevious: null,
                productSort: null,
                productSortPrevious: null,
                filtersError: error
              },
            currentPage: null,
            currentPageError: error

        }
    }),
    on(updateCatalogCategoriesNav, (state, { categories }) => {
        return {
            ...state,
            filters: {
                ...state.filters,
                productCategories: categories,
              },

        }
    }),
    on(updateCatalogCategoriesNavFailure, (state, { error }) => {
        return {
            ...state,
            filters: {
                ...state.filters,
                productCategories: null,
                filtersError: error
              },
        }
    }),

    on(getProductVariantSuccess, (state, { variantResponse }) => {
        return {
            ...state,
            productVariant: variantResponse
        }
    }),

    on(getProductVariantFailure, (state, { error }) => {
        return {
            ...state,
            productVariant: null,
            productVariantError: error
        }
    }),

    on(getProductVariantColorSuccess, (state, { variantResponse }) => {
        return {
            ...state,
            productVariantColor: variantResponse
        }
    }),

    on(getProductVariantColorFailure, (state, { error }) => {
        return {
            ...state,
            productVariantColor: null,
            productVariantColorError: error
        }
    }),

    on(updatePricingProductVariantColorSuccess, (state, { variantResponse }) => {
        return {
            ...state,
            productVariantColorPricing: variantResponse
        }
    }),
    on(updatePricingProductVariantColorFailure, (state, { error }) => {
        return {
            ...state,
            productVariantColor: null,
        }
    }),
    on(updateCatalogBrandsNav, (state, { brands }) => {
        return {
            ...state,
            filters: {
                ...state.filters,
                productBrands: brands,
              },

        }
    }),
    on(updateCatalogBrandsNavFailure, (state, { error }) => {
        return {
            ...state,
            filters: {
                ...state.filters,
                productBrands: null,
                filtersError: error
              },
        }
    }),
    on(getCartsSuccess, (state, { cartsResponse }) => {
        return {
            ...state,
            carts: cartsResponse

        }
    }),
    on(getCartsFailure, (state, { error }) => {
        return {
            ...state,
            carts: [],
            cartsError: error
        }
    }),
    on(SetSearchTermAction, (state, { searchTerm }) => {
        return { ...state, searchTerm }
    }),
    on(getCarouselHeaderSuccess, (state, { carouselHeaderResponse }) => {
        console.log('carouselHeaderResponse', carouselHeaderResponse)
        return {
            ...state,
            carouselHeader: carouselHeaderResponse
        }
    }),
    on(getCarouselHeaderFailure, (state, { error }) => {
        return {
            ...state,
            carouselHeader: null,
            carouselHeaderError: error
        }
    }),
    on(getCategoryGallerySuccess, (state, { categoryGalleryResponse }) => {
        console.log('categoryGalleryResponse', categoryGalleryResponse)
        return {
            ...state,
            categoryGallery: categoryGalleryResponse
        }
    }),
    on(getCategoryGalleryFailure, (state, { error }) => {
        return {
            ...state,
            categoryGallery: null,
            categoryGalleryError: error
        }
    }),
    on(getKeyFeaturesSuccess, (state, { keyFeaturesResponse }) => {
        console.log('keyFeaturesResponse', keyFeaturesResponse)
        return {
            ...state,
            keyFeatures: keyFeaturesResponse
        }
    }),
    on(getKeyFeaturesFailure, (state, { error }) => {
        return {
            ...state,
            keyFeatures: null,
            keyFeaturesError: error
        }
    }),
    on(getCallToActionSuccess, (state, { callToActionResponse }) => {
        console.log('callToActionResponse', callToActionResponse)
        return {
            ...state,
            callToAction: callToActionResponse
        }
    }),
    on(getCallToActionFailure, (state, { error }) => {
        return {
            ...state,
            callToAction: null,
            callToActionError: error
        }
    }),
    on(getFAQSuccess, (state, { FAQResponse }) => {
        console.log('FAQResponse', FAQResponse)
        return {
            ...state,
            FAQ: FAQResponse
        }
    }),
    on(getFAQFailure, (state, { error }) => {
        return {
            ...state,
            FAQ: null,
            FAQError: error
        }
    }),

)

export function catalogReducer(state, action) {
    return _catalogReducer(state,action)
}
