import { createAction, props } from "@ngrx/store";
import { IOrder, IOrderCounts, IOrderDetail, OrderData, OrderEstimateRequest, OrderResponse } from "../../models/order";


export const getOrderInfo = createAction(
    '[Order] Get Order',
    props<{ clientId: number, status: string, page: number, limit: number, search: string }>()

)
export const getOrderInfoSuccess = createAction(
    '[Order] Get Order Success',
    props<{ orderResponse: IOrder }>()
)
export const getOrderInfoFailure = createAction(
    '[Order] Get Order Failure',
    props<{ error: string }>()
)

export const getOrderInfoHeaders = createAction(
    '[Order] Get Order Headers',
    props<{ clientId: number }>()

)
export const getOrderInfoHeadersSuccess = createAction(
    '[Order] Get Order Headers Success',
    props<{ orderResponse: IOrderCounts }>()
)
export const getOrderInfoHeadersFailure = createAction(
    '[Order] Get Order Headers Failure',
    props<{ error: string }>()
)

export const getOrderInfoAffiliateHeaders = createAction(
    '[Order] Get Order Affiliate Headers',
    props<{ userId: number, decoratorId: number }>()

)
export const getOrderInfoAffiliateHeadersSuccess = createAction(
    '[Order] Get Order Affiliate Headers Success',
    props<{ orderResponse: IOrderCounts }>()
)
export const getOrderInfoAffiliateHeadersFailure = createAction(
    '[Order] Get Order Affiliate Headers Failure',
    props<{ error: string }>()
)

export const getAffiliateOrderInfo = createAction(
    '[Order] Get Affiliate Order',
    props<{ userId: number, decoratorId: number, status: string }>()

)
export const getAffiliateOrderInfoSuccess = createAction(
    '[Order] Get Affiliate Order Success',
    props<{ affiliateResponse: IOrder }>()
)
export const getAffiliateOrderInfoFailure = createAction(
    '[Order] Get Affiliate Order Failure',
    props<{ error: string }>()
)

export const getOrderDetail = createAction(
    '[Order] Get Order Detail',
    props<{ orderId: number}>()

)
export const getOrderDetailSuccess = createAction(
    '[Order] Get Order Detail Success',
    props<{ orderDetailResponse: [IOrderDetail] }>()
)
export const getOrderDetailFailure = createAction(
    '[Order] Get Order Detail Failure',
    props<{ error: string }>()
)

export const getOrderDetailV2 = createAction(
    '[Order] Get Order Detail V2',
    props<{ displayNumber: number, companyId: number}>()
)
export const getOrderDetailV2Success = createAction(
    '[Order] Get Order Detail V2 Success',
    props<{ response: [] }>()
)
export const getOrderDetailV2Failure = createAction(
    '[Order] Get Order Detail V2 Failure',
    props<{ error: string }>()
)

export const createOrderEstimate = createAction(
    '[Order] Create New Estimate',
    props<{ data: OrderEstimateRequest, files: any }>()
)
export const createOrderEstimateSuccess = createAction(
    '[Order] Create New Estimate Success',
    props<{ dataResponse : IOrder }>()
)
export const createOrderEstimateFailure = createAction(
    '[Order] Create New Estimate Failure',
    props<{ error: string }>()
)

export const uploadFilesNewOrderEstimate = createAction(
    '[Order] Upload Files New Order Estimate',
    props<{ fdata: any, orderId: number}>()
)
export const uploadFilesNewOrderEstimateSuccess = createAction(
    '[Order] Upload Files New Estimate Success',
    props<{ dataResponse : IOrder }>()
)
export const uploadFilesNewOrderEstimateFailure = createAction(
    '[Order] Upload Files New Estimate Failure',
    props<{ error: string }>()
)

export const createArtApprovalFeedback = createAction(
    '[Order] Create New Art Approval Feedback',
    props<{ data: any }>()
)
export const createArtApprovalFeedbackSuccess = createAction(
    '[Order] Create New Art Approval Feedback Success',
    props<{ dataResponse : any }>()
)
export const createArtApprovalFeedbackFailure = createAction(
    '[Order] Create New Art Approval Feedback Failure',
    props<{ error: string }>()
)

export const submitArtApprovalApproval = createAction(
    '[Order] Submit Art Approval',
    props<{ data: any }>()
)
export const submitArtApprovalApprovalSuccess = createAction(
    '[Order] Submit Art Approval Success',
    props<{ dataResponse : any }>()
)
export const submitArtApprovalApprovalFailure = createAction(
    '[Order] Submit Art Approval Failure',
    props<{ error: string }>()
)

export const getImagesForDesign = createAction(
    '[Order] Get Images For Design',
    props<{ data: any }>()
)
export const getImagesForDesignSuccess = createAction(
    '[Order] Get Images For Design Success',
    props<{ response : any }>()
)
export const getImagesForDesignFailure = createAction(
    '[Order] Get Images For Design Failure',
    props<{ error: string }>()
)
