<div class="cs-footer-container">
    <div class="footer-cta text-center">
        <div class="footer-cta-header">Let's get to <span class="footer-cta-gradient-text">work</span>.</div>
        <div class="footer-cta-buttons">
            <button class="footer-cta-button-1" (click)="redirectTo()">Contact Us</button>
            <!-- <button class="footer-cta-button-2">Create Now</button> -->
        </div>
    </div>
    <div class="container-xxl" style="margin-top: 125px;" >
        <div class="row">
            <div class="col-md-2 col-12 cs-footer-logo-container">
                <div class="cs-footer-logo">
                    <img loading="lazy" src="https://culturestudio.net/wp-content/uploads/2022/11/culturestudios-logo.png" alt="Culture Studio Company Logo" />
                </div>
            </div>
            <div class="col-md-2 col-4">
                <ul class="list-group list-group-light">
                    <a href="/dashboard" class="list-group-item border-0">Dashboard</a>
                    <a href="/orders" class="list-group-item border-0">Orders</a>
                    <a href="/catalog" class="list-group-item border-0">Catalog</a>                   
                </ul>
            </div>
            <div class="col-md-2 col-4">
                <ul class="list-group list-group-light">
                    <a href="https://culturestudio.net/services" class="list-group-item border-0">Services</a>
                    <a href="https://culturestudio.net/technology/" class="list-group-item border-0">Technology</a>
                    <a href="https://culturestudio.net/blog/" class="list-group-item border-0">Resources</a>
                    <a href="https://culturestudio.net/about/" class="list-group-item border-0">About</a>
                </ul>
            </div>
            <div class="col-md-2 col-4">
                <ul class="list-group list-group-light">
                    <a href="https://culturestudio.net/contact-us/" class="list-group-item border-0">Contact Us</a>
                    <a href="https://culturestudio.net/careers/" class="list-group-item border-0">Careers</a>
                    <a href="https://culturestudio.net/artwork-guidelines/" class="list-group-item border-0">Artwork Guidelines</a>
                    <a href="https://culturestudio.net/social-responsibility/" class="list-group-item border-0 text-nowrap">Social Responsibility</a>
                </ul>
            </div>
            <div class="col-md-4 col-sm-12 d-flex align-items-center justify-content-center">
                <div>
                    <!-- add cs_platform_logo.png image from the assets/images directory -->
                    <img loading="lazy" class="img-fluid" src="assets/images/cs_platform_logo.png" alt="Culture Studio Platform Logo" />
                </div>
            </div>
        </div>

        <hr class="hr" />
        
        <div class="row cs-footer-social">
            
            <div class="col-md-8 col-12 cs-copyright">
                <p><span style="padding-right: 15px;">© Copyright Chii Clothing Company {{ getCurrentYear }}</span> | <a href="https://culturestudio.net/privacy-policy/">Privacy Policy</a> | <a href="https://culturestudio.net/terms-conditions/">Terms &amp; Conditions</a> | <a href="https://culturestudio.net/accessibility/">Accessibility</a> | <a href="https://culturestudio.net/sitemap/">Sitemap</a></p>
            </div>
            <div class="col-md-4 col-12 cs-footer-social-icons"  style="text-align: right;">                
                <ul>
                    <li>
                        <a href="https://www.facebook.com/CultureStudioDecoratedMerchandise" target="_blank">
                            <i class="fab fa-facebook-f" aria-hidden="true"></i>                                                                                                                                                                                                                                                                                       
                        </a>
                    </li>                            
                    <li>
                        <a href="https://twitter.com/culturestudio" target="_blank">
                            <i class="fab fa-twitter" aria-hidden="true"></i>                                                                                                                                                                                                                                               
                        </a>
                    </li>                            
                    <li>
                        <a href="https://www.instagram.com/culturestudio/" target="_blank">
                            <i class="fab fa-instagram" aria-hidden="true"></i>                                                                                                                                                                                                       
                        </a>
                    </li>
                    
                    <li>
                        <a href="https://www.linkedin.com/company/culture-studio" target="_blank">
                            <i class="fab fa-linkedin" aria-hidden="true"></i>                                                                                                                                                               
                        </a>
                    </li>

                    <li>
                        <a href="https://www.pinterest.com/JoinTheCulture/" target="_blank">                                                                                                                                                                    
                            <i class="fab fa-pinterest" aria-hidden="true"></i>                                                                                                                       
                        </a>
                    </li>
                    
                    <li>
                        <a href="https://www.youtube.com/@culturestudio" target="_blank">                                                                                                                                                                                                            
                            <i class="fab fa-youtube" aria-hidden="true"></i>                                                                               
                        </a>
                    </li>
                    
                    <li>
                        <a href="https://www.tiktok.com/@culturestudio" target="_blank">                                                                                                                                                                                                                                                
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2859 3333" shape-rendering="geometricPrecision" text-rendering="geometricPrecision" image-rendering="optimizeQuality" fill-rule="evenodd" clip-rule="evenodd" style="fill: #0F75DB;height: 20px;"><path d="M2081 0c55 473 319 755 778 785v532c-266 26-499-61-770-225v995c0 1264-1378 1659-1932 753-356-583-138-1606 1004-1647v561c-87 14-180 36-265 65-254 86-398 247-358 531 77 544 1075 705 992-358V1h551z"></path></svg>                                       
                        </a>
                    </li>
                </ul>                            
                     
            </div>            
        </div>
    </div>
</div>
