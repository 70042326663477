import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, catchError, firstValueFrom, forkJoin, map, of, share, tap } from 'rxjs';
import { OrderEstimateRequest } from '@core/models/order';
import { ApiService } from '@core/services/api/api.service';
import { HelperService } from '@core/services/api/helper.service';
import { ErrorsService } from '@core/services/errors/errors.service';
import { DataLayerService } from '@core/services/seo/data-layer.service';
import { SessionService } from '@core/services/session/session.service';
import * as OrderActions from '@core/state/order/order.actions';
import { environment } from 'environments/environment';
import { AuthService } from '@auth0/auth0-angular';

@Injectable({
  providedIn: 'root'
})
export class OrdersService {
  protected apiRoot: string;
  protected apiMicroservice: string;

  constructor(
    protected apiService: ApiService,
    private helperService: HelperService,
    private store: Store,
    protected http: HttpClient,
    private dataLayerService: DataLayerService,
    private router: Router,
    private session: SessionService,
    private authService: AuthService,
    private errorsService: ErrorsService
  ) {
    this.apiRoot = environment.apiRoot;
    this.apiMicroservice = environment.apiMicroservice;
   }

  public getAllOrders(client_id, type, page, limit, search) :Observable<Object> {
    const validateSearch = search ? search.trim() : '';
    // Construct the URL based on whether a search term is provided or not
    let url = `${this.apiMicroservice}legacy/cp/orders/${client_id}/${type}/${page}/${limit}`;

    if (validateSearch) {
      url += `?search=${encodeURIComponent(validateSearch)}`;  // Add search as a query parameter
    }

    // Perform the GET request
    return this.http.get(url).pipe(share());
  }

  // get order headers based on client_id
  public getAllOrderHeaders(client_id) :Observable<Object> {
    let orders$ = this.apiService.getV2('api/v2/order/platform/headers/client/'+client_id).pipe(share())

    return orders$;
  }

  // get order headers affiliare based on client_id
  public getAllOrderAffiliateHeaders(user_id, decorator_id) :Observable<Object> {
    let orders$ = this.apiService.getV2('api/v2/order/platform/headers/affiliate/'+ user_id + '/' + decorator_id).pipe(share())

    return orders$;
  }

  public getAllAffiliateOrders(userId, decoratorId, type): Observable<Object> {
      let orders$ = this.apiService.getV2('api/v2/order/platform/'+userId + '/'+ decoratorId + '/' + type).pipe(share())

      return orders$;
  }

  getOrderDetail(orderId): Observable<Object> {
      let orderDetail$ = this.apiService.get('orders/'+orderId).pipe(
        catchError(async (error) => {
          if(error.status == 401){
            const client = this.session.getActiveClient();
            const authenticated = await firstValueFrom(this.authService.isAuthenticated$)
            if (authenticated) {
              this.errorsService.setErrorState({
                  imageUrl: 'assets/images/oops-t-shirt.png',
                  title: 'Order not found',
                  message: `This order: <strong>${orderId}</strong> is not available with your current client: <strong>${client.name}</strong>.`,
                  buttons: [
                    {
                    routerLink: `/login/choose-client`,
                    routerParams: {redirect: `/orders/${orderId}`},
                    text: 'Change Client'
                    }
                  ]
                })
                this.errorsService.showError()
            } else {
              this.router.navigate(['/login']);
            }
          }
          return of(error);
        }),
      )

      return orderDetail$
  }

  public getOrderDetailV2(displayNumber, companyId): Observable<Object> {
    return this.apiService.getV2(`api/v2/order/client-portal/approvals/${displayNumber}/${companyId}`).pipe(share())
  }

  public createOrderEstimate(order: OrderEstimateRequest, files: any): Observable<Object>{
    let estimate$ = this.apiService.postV2('api/v2/order/platform/request-estimate', order).pipe(
      tap({
        next: (result) => {
          let orderId = result['id']
          this.dataLayerService.logOrderConversionEvent("OrderEstimateSubmit", "'Buttons'", "'Clicked'", "OrderEstimateSubmitButton", {orderId: orderId, data: order})
          this.store.dispatch(OrderActions.uploadFilesNewOrderEstimate({fdata: files, orderId}))
        },
        error: (error) => console.warn('create order estimate', error)
      }
      )
    );

    return estimate$;

  }

  public uploadFilesOrderEstimate(files, orderId): Observable<Object>{
    let upload = this.formatFileUpload(files, orderId);
    let upload$ = this.http.post(`${this.apiRoot}upload/create-multi`, upload, {reportProgress: true});

    return upload$;
  }

  public formatFileUpload(files, orderId){
    let date = new Date()
    const formData: FormData = new FormData()
    formData.append('file_count', files.length)
    formData.append('order_id', orderId)
    formData.append('created_date', this.helperService.getCurrentDate(date))

    files.map((f, i) => {
      let file: File = f;
      return formData.append('file_'+(i+1), file)
    })
    return formData;
  }

  public downloadPdf(orderId: number, companyId: number, designId: number): Observable<any> {
    return this.http.post(`${this.apiRoot}client-portal/art/download-art-approval/${orderId}/${companyId}/${designId}`, {
      pdf_token: true
    }, { headers: { 'Accept': 'application/pdf' }, responseType: 'blob' }).pipe(
      map((data: any) => {
        this.savePdf(data, orderId, designId);
      })
    );
  }

  private savePdf(data: any, orderId: number, designId: number): void {
    const blob = new Blob([data], { type: 'application/pdf' });
    const url = (window.URL || window.webkitURL).createObjectURL(blob);

    const today = new Date();
    const a: any = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display: none";
    a.href = url;
    a.download = `ArtApproval_OrderNum${orderId}_DesignNum${designId}_${today.getMonth() + 1}_${today.getDate()}_${today.getFullYear()}`;
    a.click();
    window.URL.revokeObjectURL(url);
  }

  public getFeedback(companyId: number, designId: number): Observable<any> {
    return this.apiService.getV2(`api/v2/feedback/${companyId}/${designId}`);
  }

  public createFeedback(payload: any): Observable<any> {
    const { company_id: companyId, design_id: designId } = payload;
    return this.apiService.postV2(`api/v2/feedback/${companyId}/${designId}`, payload).pipe(
      map(response => {
        this.artApprovalEmailResponse('feedback',payload.order_id, companyId, designId, payload).subscribe(
          () => {
            console.log('email sent');
          }, error => {
            console.log('email error', error);
          }
        )
        return response;
      })
    )
  }

  public getImagesForDesign(payload: any): Observable<any> {
    const { companyId, designId } = payload;
    const artApprovals = this.http.get<string[]>(`${environment.apiUrl2}api/v2/cloud/platform/art-approval/${companyId}/${designId}`);
    const photoApprovals = this.http.get<string[]>(`${environment.apiUrl2}api/v2/cloud/platform/photo-approval/${companyId}/${designId}`);
    return forkJoin([artApprovals, photoApprovals]).pipe(
      map(([art, photo]) => [...art, ...photo])
    );
  }

  public artApprovalEmailResponse(type, order_id, company_id, design_id, payload): Observable<any>{
    return this.apiService.post(`art/${type}/${order_id}/${company_id}/${design_id}`,payload, { pdf_token: true });
  }
}
