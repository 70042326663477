<ng-container *ngIf="screenWidth > 800; else mobileNav">
  <div class="row px-2 py-2 navbar-main">
    <div class="col-sm-6 d-flex align-items-center justify-content-start">
      <div class="d-flex">
        <ng-container>
          <a class="navbar-brand-img d-flex align-items-center ms-4" [href]="rootUrl">
            <img [src]="getLogo()" height="50" alt="Culture Studio Company Logo" loading="lazy" />
          </a>
        </ng-container>
        <div class="">
          <ul class="navbar-nav flex-row">
            <ng-container *ngFor="let nav of navigation">
              <li *ngIf="nav.show" class="nav-item position-relative">
                <span class="badge badge-warning translate-middle badge nav-badge" *ngIf="nav.label?.text?.length > 0" [ngStyle]="{color: nav.label.textColor, background: nav.label.backgroundColor}">{{nav.label.text}}</span>
                <a class="btn shadow-0 nav-link nav-link-sub" routerLinkActive="active" [routerLink]="nav.link"
                  role="button">
                  {{ nav.name }}
                </a>
              </li>
            </ng-container>
          </ul>
        </div>
      </div>
    </div>

    <div class="col-sm-6 d-flex align-items-center justify-content-end text-nowrap">
      <ng-container *ngIf="authenticated && activeDecorator && user.is_affiliate_user == 0">
        <div mdbDropdown>
          <div mdbDropdownToggle id=dropdownCart aria-expanded="false" class="cart-button" role="button">
            <i class="fas fa-shopping-cart"></i>
            <span class="cart-text">Carts ({{carts ? carts.length : ''}})</span>
            <i class="fas fa-angle-down mx-1"></i>
          </div>
          <div mdbDropdownMenu class="dropdown-menu navbar-user-dropdown dropdown-menu-end"
            aria-labelledby="dropdownMenuLink">
            <div class="custom-accordion">
              <ng-container *ngFor="let cart of carts; let i = index">
                <div class="accordion-item">
                  <div class="accordion-header" (click)="cart?.designs?.length === 0 ? null : toggleAccordion(i)"
                    [ngClass]="cart?.designs?.length === 0 ? 'pe-none' : ''">
                    <div class="row">
                      <div class="col-10">
                        <div class="accordion-title">{{ cart.cartName }}</div>
                        <span>{{cart.designs.length}} {{cart.designs.length === 1 ? 'design' : 'designs'}}</span>
                      </div>
                      <ng-container *ngIf="cart && cart.designs.length > 0; else noDesigns">
                        <div class="col-2 d-flex align-items-center justify-content-center">
                          <span *ngIf="selectedItemIndex === i" class="accordion-icon">
                            <i  class="fas fa-chevron-down fa-xs"></i>
                          </span>
                          <span *ngIf="selectedItemIndex != i" class="accordion-icon">
                            <i  class="fas fa-chevron-right fa-xs"></i>
                          </span>
                        </div>
                      </ng-container>
                      <ng-template #noDesigns>
                        <div class="col-2 d-flex align-items-center justify-content-end">
                          <i class="fas fa-trash-alt pe-auto" style="margin-right: 5.5px;" mdbTooltip="Delete Cart"
                            placement="left" (click)="deleteCart(cart); $event.stopPropagation()"></i>
                        </div>
                      </ng-template>
                    </div>
                  </div>
                  <ng-container *ngIf="cart">
                    <app-cart-item-template [carts]="cart" [isOpen]="selectedItemIndex === i"></app-cart-item-template>
                  </ng-container>
                </div>
              </ng-container>
              <div class="accordion-item">
                <div class="accordion-header">
                  <div *ngIf="!showNewCartInput" class="accordion-title" (click)="toggleNewCartInput($event)">
                    <i class="fas fa-plus me-2"></i> New cart
                  </div>
                  <div *ngIf="showNewCartInput">
                    <div>Cart name</div>
                    <input class="new-cart-input w-100" type="text" placeholder="Add a name..." [(ngModel)]="cartName">
                    <div class="mt-2">
                      <span class="cart-cancel me-2" (click)="toggleNewCartInput($event)">Cancel</span>
                      <button class="cart-add" (click)="addNewCart()">Continue</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>

      <ul class="navbar-nav-r">
        <div class="navbar-nav-ul flex-row navbar-nav">
          <ng-container *ngIf="authenticated; else login">
            <span class="rounded-circle bg-secondary p-2 login-avatar d-flex align-items-center">{{ getUserAcronym() }}</span>
            <div mdbDropdown class="d-flex align-items-center">
              <ul class="list-unstyled ms-2" type="button" id="dropdownMenuButton" aria-expanded="false"
                mdbDropdownToggle data-mdb-display="static">
                <li class="nav-name">
                  {{ user.name }}
                </li>
                <li class="nav-name-client">{{ clientName }}
                </li>
              </ul>
              <ul mdbDropdownMenu class="dropdown-menu navbar-user-dropdown dropdown-menu-end"
                aria-labelledby="dropdownMenuLink">
                <li>
                  <ng-container *ngIf="activeDecorator && clients?.length > 1">
                    <a class="dropdown-item" href="/login/choose-client">
                      <i class="fas fa-users-cog pe-1"></i>
                      Change Client
                    </a>
                  </ng-container>
                </li>
                <li>
                  <hr class="dropdown-divider" />
                </li>
                <li><a class="dropdown-item" (click)="logout()">
                    <i class="fas fa-sign-out-alt pe-1"></i>
                    Sign out</a></li>
              </ul>
            </div>
            <li class="ms-2 mt-1">
              <i class="fas fa-angle-down"></i>
            </li>
          </ng-container>
        </div>
      </ul>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="screenWidth > 800; else mobileContainer">
    <div class="content-wrapper">
      <content class="col-sm-12 main-height-content">
        <router-outlet></router-outlet>
      </content>
    </div>
</ng-container>

<!-- TEMPLATES -->
<ng-template #mobileNav>
  <div class="row px-2 py-2 navbar-dark navbar-sidenav-mobile navbar-main-mobile">
    <div class="col-sm-12">
      <mdb-sidenav-layout>
        <mdb-sidenav #sidenav="mdbSidenav">

          <ng-container>
            <a class="ripple d-flex justify-content-center py-4 mb-1" href="/catalog" data-mdb-ripple-color="primary">
              <img [src]="getLogo()" height="40" alt="Culture Studio Company Logo" loading="lazy" />
            </a>
          </ng-container>


          <div style="border-bottom: 1px solid #19212E; width: 85%; margin-left: auto; margin-right: auto;"></div>

          <ul class="sidenav-menu overflow-hidden">
              <mdb-sidenave-item *ngFor="let nav of subNavigationCS">

                <mdb-accordion [flush]="true" *ngIf="nav.link === '/catalog'">
                  <mdb-accordion-item class="position-relative">
                    <ng-template mdbAccordionItemHeader>
                      <i [ngClass]="nav.icon" class="fas me-3 catalog-mobile-nav-icon"></i>
                      <span class="catalog-mobile-nav">{{ nav.name }}</span>
                      <span class="badge badge-warning translate-middle badge nav-badge" *ngIf="nav.label?.text?.length > 0" [ngStyle]="{color: nav.label.textColor, background: nav.label.backgroundColor}">{{nav.label.text}}</span>
                    </ng-template>
                    <ng-template mdbAccordionItemBody>
                      <ul class="sidenav-menu">
                        <a class="sidenav-link mobile-menu p-0" tabindex="0" (click)="brandCollapse.toggle()"
                          [attr.aria-expanded]="!brandCollapse.collapsed" aria-controls="collapseExample">
                          <span>Brands</span><i
                            [ngClass]="brandCollapse.collapsed ? 'fas fa-angle-down' : 'fas fa-angle-up'"></i>
                        </a>
                        <ul class="sidenav-collapse" mdbCollapse #brandCollapse="mdbCollapse">
                          <ng-container *ngFor="let brand of brands">
                            <li class="sidenav-item">
                              <a class="sidenav-link" tabindex="0"
                                [href]="'/catalog/all?' + 'brand' + '=' + brand">{{brand}}</a>
                            </li>
                          </ng-container>
                        </ul>
                      </ul>

                      <ul class="sidenav-menu">
                        <a class="sidenav-link mobile-menu p-0" tabindex="0" (click)="decorationCollapse.toggle()"
                          [attr.aria-expanded]="!decorationCollapse.collapsed" aria-controls="collapseExample">
                          <span>Decorations</span><i
                            [ngClass]="decorationCollapse.collapsed ? 'fas fa-angle-down' : 'fas fa-angle-up'"></i>
                        </a>
                        <ul class="sidenav-collapse" mdbCollapse #decorationCollapse="mdbCollapse">
                          <ng-container *ngFor="let decoration of decorations">
                            <li class="sidenav-item">
                              <a class="sidenav-link" tabindex="0"
                                [href]="'/catalog/all?' + 'decoration' + '=' + decoration">{{decoration}}</a>
                            </li>
                          </ng-container>
                        </ul>
                      </ul>

                      <ul class="sidenav-menu">
                        <a class="sidenav-link mobile-menu p-0" tabindex="0" (click)="categoryCollapse.toggle()"
                          [attr.aria-expanded]="!categoryCollapse.collapsed" aria-controls="collapseExample">
                          <span>Apparel</span><i
                            [ngClass]="categoryCollapse.collapsed ? 'fas fa-angle-down' : 'fas fa-angle-up'"></i>
                        </a>
                        <ul class="sidenav-collapse" mdbCollapse #categoryCollapse="mdbCollapse">
                          <ng-container *ngFor="let category of categories">
                            <li class="sidenav-item">
                              <a class="sidenav-link" tabindex="0"
                                [href]="'/catalog/all?' + 'categories' + '=' + category">{{category | titlecase}}</a>
                            </li>
                          </ng-container>
                        </ul>
                      </ul>

                      <ul class="sidenav-menu">
                        <a class="sidenav-link mobile-menu p-0" tabindex="0"
                          [attr.aria-expanded]="!categoryCollapse.collapsed" aria-controls="collapseExample"
                          [href]="'/catalog/all?' + 'categories' + '=' + 'collectibles'">
                          <span>Collectibles</span><i
                            [ngClass]="categoryCollapse.collapsed ? 'fas fa-angle-down' : 'fas fa-angle-up'"></i>
                        </a>
                      </ul>

                      <ul class="sidenav-menu">
                        <a class="sidenav-link mobile-menu p-0" tabindex="0"
                          [attr.aria-expanded]="!categoryCollapse.collapsed" aria-controls="collapseExample"
                          [href]="'/catalog/all?' + 'categories' + '=' + 'posters'">
                          <span>Poster/Wall Art</span><i
                            [ngClass]="categoryCollapse.collapsed ? 'fas fa-angle-down' : 'fas fa-angle-up'"></i>
                        </a>
                      </ul>

                      <ul class="sidenav-menu">
                        <a class="sidenav-link mobile-menu p-0" tabindex="0"
                          [attr.aria-expanded]="!categoryCollapse.collapsed" aria-controls="collapseExample"
                          [href]="'/catalog/all?' + 'brand' + '=' + 'private label'">
                          <span>Custom Products</span><i
                            [ngClass]="categoryCollapse.collapsed ? 'fas fa-angle-down' : 'fas fa-angle-up'"></i>
                        </a>
                      </ul>

                      <ul class="sidenav-menu">
                        <a class="sidenav-link mobile-menu p-0" tabindex="0"
                          [attr.aria-expanded]="!categoryCollapse.collapsed" aria-controls="collapseExample"
                          [href]="'/catalog/all?' + 'decoration' + '=' + 'sublimation'">
                          <span>Cut & Sew</span><i
                            [ngClass]="categoryCollapse.collapsed ? 'fas fa-angle-down' : 'fas fa-angle-up'"></i>
                        </a>
                      </ul>

                      <ul class="sidenav-menu">
                        <a class="sidenav-link mobile-menu p-0" tabindex="0"
                          [attr.aria-expanded]="!categoryCollapse.collapsed" aria-controls="collapseExample"
                          [href]="'/catalog/all?' + 'brand' + '=' + 'private label collecXion'">
                          <span>Private Label CollecXion</span><i
                            [ngClass]="categoryCollapse.collapsed ? 'fas fa-angle-down' : 'fas fa-angle-up'"></i>
                        </a>
                      </ul>

                    </ng-template>
                  </mdb-accordion-item>
                </mdb-accordion>

                <li class="nav-item position-relative" *ngIf="nav.link != '/catalog'">
                  <span class="badge badge-warning translate-middle badge nav-badge" *ngIf="nav.label?.text?.length > 0" [ngStyle]="{color: nav.label.textColor, background: nav.label.backgroundColor}">{{nav.label.text}}</span>
                  <a class="sidenav-link" routerLinkActive="active" [routerLink]="nav.link" role="button">
                    <i [ngClass]="nav.icon" class="fas me-3 catalog-mobile-nav-icon"></i>
                    <span>{{ nav.name }}</span>
                  </a>
                </li>
              </mdb-sidenave-item>
          </ul>
        </mdb-sidenav>
        <mdb-sidenav-content #sidenavContent>
          <div class="row">
            <div class="col-2">
              <button #sidenavToggle class="btn btn-link" style="color: #FFF;" (click)="sidenav.toggle()">
                <i class="fas fa-bars fa-lg"></i>
              </button>
            </div>
            <div class="col-8">
                <a class="ripple d-flex justify-content-center" href="/catalog" data-mdb-ripple-color="primary">
                  <img src="https://img.stokkup.com/app/cs-circle-logo.png" height="40" alt="Culture Studio Company Logo" loading="lazy" />
                </a>
            </div>
            <div class="col-2 d-flex align-items-center justify-content-center">
              <ng-container *ngIf="authenticated && activeDecorator && user.is_affiliate_user == 0">
                <div mdbDropdown>
                  <div mdbDropdownToggle id=dropdownCart aria-expanded="false" role="button" class="me-3">
                    <i class="fas fa-shopping-cart me-2" style="color: #FFF;"></i>
                    <ng-container *ngIf="carts && carts.length > 0">
                      <span class="rounded-pill cart-badge bg-danger text-white">{{carts ? carts.length : ''}}</span>
                    </ng-container>
                  </div>
                  <div mdbDropdownMenu class="dropdown-menu navbar-user-dropdown dropdown-menu-end"
                    aria-labelledby="dropdownMenuLink">
                    <div class="">
                      <ng-container *ngFor="let cart of carts; let i = index">
                        <div class="accordion-item">
                          <div class="accordion-header"
                            (click)="cart?.designs?.length === 0 ? null : toggleAccordion(i)"
                            [ngClass]="cart?.designs?.length === 0 ? 'pe-none' : ''">
                            <div class="row">
                              <div class="col-10">
                                <div class="accordion-title">{{ cart.cartName }}</div>
                                <span>{{cart.designs.length}} {{cart.designs.length === 1 ? 'design' :
                                  'designs'}}</span>
                              </div>
                              <ng-container *ngIf="cart && cart.designs.length > 0">
                                <div class="col-2 d-flex align-items-center justify-content-center">
                                  <span *ngIf="selectedItemIndex === i" class="accordion-icon">
                                    <i  class="fas fa-chevron-down fa-xs"></i>
                                  </span>
                                  <span *ngIf="selectedItemIndex != i" class="accordion-icon">
                                    <i  class="fas fa-chevron-right fa-xs"></i>
                                  </span>
                                </div>
                              </ng-container>
                            </div>
                          </div>
                          <ng-container *ngIf="cart">
                            <app-cart-item-template [carts]="cart"
                              [isOpen]="selectedItemIndex === i"></app-cart-item-template>
                          </ng-container>
                        </div>
                      </ng-container>
                      <div class="accordion-item">
                        <div class="accordion-header">
                          <div *ngIf="!showNewCartInput" class="accordion-title" (click)="toggleNewCartInput($event)">
                            <i class="fas fa-plus me-2"></i> New cart
                          </div>
                          <div *ngIf="showNewCartInput">
                            <div>Cart name</div>
                            <input class="new-cart-input w-100" type="text" placeholder="Add a name..."
                              [(ngModel)]="cartName">
                            <div class="mt-2">
                              <span class="cart-cancel me-2" (click)="toggleNewCartInput($event)">Cancel</span>
                              <button class="cart-add" (click)="addNewCart()">Continue</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>
              <ng-container *ngIf="authenticated; else login">
                <div mdbDropdown>
                  <button class="bg-transparent float-end" type="button" id="dropdownMenuButton" aria-expanded="false"
                    mdbDropdownToggle data-mdb-display="static">
                    <i class="fas fa-user me-3" style="color: #FFF"></i>
                  </button>

                  <ul mdbDropdownMenu class="dropdown-menu navbar-user-dropdown dropdown-menu-end"
                    aria-labelledby="dropdownMenuLink">
                    <li class="profile-mobile-header"><b>Hi, {{ user.name }}!</b></li>
                    <li *ngIf="clientName" class="profile-mobile-header">Client: <span class="sidenav-client">{{ clientName }}</span></li>
                    <li>
                      <ng-container *ngIf="activeDecorator && clients?.length > 1">
                        <a class="dropdown-item" href="/login/cs/choose-client">
                          <i class="fas fa-users-cog pe-1"></i>
                          Change Client
                        </a>
                      </ng-container>
                    </li>
                    <li>
                      <hr class="dropdown-divider" />
                    </li>
                    <li><a class="dropdown-item" (click)="logout()">
                        <i class="fas fa-sign-out-alt pe-1"></i>
                        Sign out</a></li>
                  </ul>
                </div>
              </ng-container>
            </div>
          </div>
        </mdb-sidenav-content>
      </mdb-sidenav-layout>
    </div>
  </div>
</ng-template>

<ng-template #login>
  <a class="avatar-link d-flex align-items-center" (click)="goToLogin()">Login</a>
</ng-template>

<ng-template #loginmobile>
  <div class="sidenav-mobile-login text-center">
    <a class="avatar-link d-flex align-items-center" (click)="goToLogin()">Login</a>
  </div>
</ng-template>

<ng-template #subNavCatalogMobile>
  <mdb-sidenave-item *ngFor="let nav of subNavigationCatalog">
    <mdb-accordion [flush]="true">
      <mdb-accordion-item>
        <ng-template mdbAccordionItemHeader> <i
            [ngClass]="nav.name == 'Dashboard' ? 'fas fa-home me-3' : nav.name == 'Orders' ? 'fas fa-clipboard-list me-3' : nav.name == 'Catalog' ? 'fas fa-book-open me-3' : null "></i>{{
          nav.name }}</ng-template>
        <ng-template mdbAccordionItemBody>
          <ul class="sidenav-menu">
            <a class="sidenav-link mobile-menu" tabindex="0" (click)="brandCollapse.toggle()"
              [attr.aria-expanded]="!brandCollapse.collapsed" aria-controls="collapseExample">
              <span>Brands</span><i [ngClass]="brandCollapse.collapsed ? 'fas fa-angle-down' : 'fas fa-angle-up'"></i>
            </a>
            <ul class="sidenav-collapse" mdbCollapse #brandCollapse="mdbCollapse">
              <ng-container *ngFor="let brand of brands">
                <li class="sidenav-item">
                  <a class="sidenav-link" tabindex="0" [href]="'/catalog/all?' + 'brand' + '=' + brand">{{brand}}</a>
                </li>
              </ng-container>
            </ul>
          </ul>

          <ul class="sidenav-menu">
            <a class="sidenav-link mobile-menu" tabindex="0" (click)="categoryCollapse.toggle()"
              [attr.aria-expanded]="!categoryCollapse.collapsed" aria-controls="collapseExample">
              <span>Categories</span><i
                [ngClass]="categoryCollapse.collapsed ? 'fas fa-angle-down' : 'fas fa-angle-up'"></i>
            </a>
            <ul class="sidenav-collapse" mdbCollapse #categoryCollapse="mdbCollapse">
              <ng-container *ngFor="let category of categories">
                <li class="sidenav-item">
                  <a class="sidenav-link" tabindex="0"
                    [href]="'/catalog/all?' + 'categories' + '=' + category">{{category}}</a>
                </li>
              </ng-container>
            </ul>
          </ul>

          <ul class="sidenav-menu">
            <a class="sidenav-link mobile-menu" tabindex="0" (click)="decorationCollapse.toggle()"
              [attr.aria-expanded]="!decorationCollapse.collapsed" aria-controls="collapseExample">
              <span>Decorations</span><i
                [ngClass]="decorationCollapse.collapsed ? 'fas fa-angle-down' : 'fas fa-angle-up'"></i>
            </a>
            <ul class="sidenav-collapse" mdbCollapse #decorationCollapse="mdbCollapse">
              <ng-container *ngFor="let decoration of decorations">
                <li class="sidenav-item">
                  <a class="sidenav-link" tabindex="0"
                    [href]="'/catalog/all?' + 'decoration' + '=' + decoration">{{decoration}}</a>
                </li>
              </ng-container>
            </ul>
          </ul>
        </ng-template>
      </mdb-accordion-item>
    </mdb-accordion>
  </mdb-sidenave-item>
</ng-template>

<ng-template #mobileContainer>
  <div class="mx-2">
    <content class="mobile-container">
      <router-outlet></router-outlet>
    </content>
  </div>
</ng-template>

