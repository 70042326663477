import { Component, HostListener, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { SessionService } from "../services/session/session.service";
import { Router } from "@angular/router";
import { onMainContentChange } from '../animations';
import { filter, first, firstValueFrom, Subject, takeUntil } from 'rxjs';
import { MdbDropdownDirective } from 'mdb-angular-ui-kit/dropdown';
import { Store, select } from '@ngrx/store';
import { HelperService } from '../services/api/helper.service';
import { ApiService } from '../services/api/api.service';
import * as CatalogActions from '@core/state/catalog/catalog.actions';
import * as fromCatalog from '@core/state/catalog/catalog.selectors';
import { MongoDbService } from '../services/api/mongodb.service';
import * as fromUser from '@core/state/user/user.selector';
import { SharedService } from '@shared/shared.service';
import { AuthService, User } from '@auth0/auth0-angular';
import { Client } from '@core/models/client';
import { environment } from 'environments/environment';
import { Decorator } from '@core/models/decorator';
import { Cart } from '@core/models/catalog';

@Component({
  selector: 'app-base',
  templateUrl: './base.component.html',
  styleUrls: ['./base.component.scss'],
  animations: [onMainContentChange]
})
export class BaseComponent implements OnInit, OnDestroy {
  rootUrl = environment.baseUrl;
  onSideNavChange: boolean;
  year = new Date().getFullYear();
  viewport;
  destroyed$ = new Subject<void>();
  isOpen: boolean;
  activeDecorator?: number|null;
  clientName: string = '';
  user: User;
  authenticated: boolean
  search: string;
  decorator: Decorator;
  screenWidth: number;
  brands: any
  brand: any;
  categories: any
  category: string = '';
  decorations: any
  decorationType: string = '';
  showNewCartInput: boolean = false;
  cartName: string = '';
  selectedItemIndex: number = -1; // Index of the currently selected accordion item
  navigation = [];
  subNavigationCS = [
    { link: '/dashboard', name: 'Dashboard', icon: 'fa-home me-3' },
    { link: '/orders', name: 'Orders', hide: false, icon: 'fa-clipboard-list' },
    { 
      link: '/floor-stock',
      name: 'Floor Stock',
      hide: false,
    },
    { link: '/catalog', name: 'Catalog', hide: true, icon: 'fa-book-open' },
  ]
  subNavigationCatalog = [
    { link: '/catalog', name: 'Catalog', hide: true }
  ]
  clientId: number;
  @Input() carts: Cart[]
  @ViewChild('dropdown') dropdown!: MdbDropdownDirective;
  clients: Client[];

  constructor(
    protected session: SessionService,
    protected router: Router,
    protected api: ApiService,
    protected auth: AuthService,
    private store: Store,
    private helper: HelperService,
    private _mongo: MongoDbService,
    private sharedService: SharedService,
  ) {}

  async ngOnInit() {
    this.decorator = this.session.getActiveDecorator();
    this.authenticated = await firstValueFrom(this.auth.isAuthenticated$);
    this.user = await firstValueFrom(this.auth.user$)
    
    if(this.authenticated) {
      this.clientId = this.session.getActiveClient()?.id
      if (this.clientId) {
        this.store.dispatch(CatalogActions.getCarts({ clientId: this.clientId }));
      }
    }
    this.store.select(fromUser.selectClients).pipe(filter(resp => !!resp), first()).subscribe((resp) => this.clients = resp)
    this.activeDecorator = this.decorator ? this.decorator.id : null

    this.getNavigation();

    this.clientName = this.session.getActiveClient() ? this.session.getActiveClient().name : this.clientName;
    this.screenWidth = window.innerWidth;

    if (this.router.url === '/') {
      let client = this.session.getActiveClient();
      let decorator = this.session.getActiveDecorator();
      
      if (this.user && decorator && client) {
        this.router.navigate(['/orders']);
      } else if (this.user && decorator) {
        this.router.navigate(['/choose/client']);
      } else if (this.user) {
        this.router.navigate(['/choose/decorator']);
      } else {
        this.router.navigate(['/catalog']);
      }
    }

    this.store.pipe(select(fromCatalog.selectCarts), takeUntil(this.destroyed$)).subscribe((carts => {
      this.carts = carts
    }));

    this.getBrands()
    this.getCategories()
    this.getDecoration()
  }

  getNavigation() {
    this.navigation = [
      { link: '/dashboard',
        name: 'Dashboard',
        show: (this.activeDecorator && this.authenticated)
      },
      {
        link: '/orders',
        name: 'Orders',
        show: this.authenticated
      },
      {
        link: '/floor-stock',
        name: 'Floor Stock',
        show: (this.activeDecorator && this.authenticated),
      },
      {
        link: '/catalog',
        name: 'Catalog',
        show: (this.activeDecorator && this.authenticated)
      }
    ]
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.screenWidth = window.innerWidth;
  }

  toggleAccordion(index: number): void {
    if (this.selectedItemIndex === index) {
      this.selectedItemIndex = -1; // Collapse if already open
    } else {
      this.selectedItemIndex = index; // Expand the selected item
    }
  }

  toggleNewCartInput(event: Event) {
    event.stopPropagation(); // Prevent the click event from propagating
    this.showNewCartInput = !this.showNewCartInput;
    this.cartName = ''; // Clear input field when toggling
  }

  /**
 * Asynchronous function to add a new cart.
 */
  async addNewCart() {
    const cart = await this._mongo.cartTemplate()

    if (this.cartName) {
      cart.cartName = this.cartName;
      this.toggleNewCartInput(event); // Hide input field after adding
    } else {
      this.openAlert('Please input a Cart name.');
      return
    }

    this.store.dispatch(CatalogActions.addCart({
      cart: cart
    }));

    this.store.pipe(select(fromCatalog.selectCarts), takeUntil(this.destroyed$)).subscribe((carts => {
      this.carts = carts
    }));
  }


  /**
 * Function to delete a cart item.
 *
 * @param {Object} cart - The cart object to be deleted.
 */
  deleteCart(cart) {
    if (confirm(`Are you sure you want to delete ${cart.cartName}? This will remove the cart permanently.`)) {
      const deleteCart = { ...cart, _id: undefined, version: this.helper.getTimestamp(), active: false };

      this.store.dispatch(CatalogActions.addCart({
        cart: deleteCart
      }));
      this.store.pipe(select(fromCatalog.selectCarts)).pipe(takeUntil(this.destroyed$)).subscribe((carts => {
        this.carts = carts
      }));
    }
  }

  logout() {
    this.auth.logout({
      openUrl: (url) => {
        this.session.flushUser();
        window.location.replace(url);
      },
      logoutParams: {
        returnTo: window.location.origin,
        federated: true
      },
    })
  }

  /**
 * Get list of brands from MongoDB
 */
  protected getBrands() {
    this._mongo.getBrands().pipe(takeUntil(this.destroyed$)).subscribe(
      (data: any) => {
        this.brands = data; // Assign the data to your brands array

        this.store.dispatch(CatalogActions.updateCatalogBrandsNav(
          {
            brands: { 'Brands': this.brands }
          }
        ));
      }
    );
  }

  /**
 * Mongo endpoint to get the categories for all products in the catalog
 */
  protected getCategories() {
    this._mongo.getCategories().pipe(takeUntil(this.destroyed$)).subscribe(
      (data: any) => {
        this.categories = data; // Assign the data to your categories array

        this.store.dispatch(CatalogActions.updateCatalogCategoriesNav(
          {
            categories: { 'Categories': this.categories }
          }
        ));
      }
    );
  }

  protected getDecoration() {
    this.decorations = [
      'Sublimation',
      'Branded Merchandise',
      'Embroidery',
      'Dye & Wash',
      'Woven / Patch',
      'Laser',
      'On Demand',
      'Transfer',
      'Print'
    ]
  }

  getLogo() {
    return 'https://img.stokkup.com/app/cs-circle-logo.png';
  }

  filterNavbar(key: string, item: string) {
    this.search = !key ? '' : this.search
    if (!key && !item) {
      this.router.navigate(['catalog/'])
    } else {
      let url = `catalog/all`
      this.router.navigate([url], { queryParams: { [key]: item } })
    }
  }

  openAlert(prompt) {
    this.sharedService.showNotification({ data: { title: prompt, class: '' }, autohide: true });
  }

  ngOnDestroy() {
    this.destroyed$.next()
  }

  getUserAcronym(): string {
    const parts = this.user?.name?.split(' ')?.filter(e => e.length > 0)
    if (parts && parts.length > 1) {
      return parts![0][0] + parts![1][0]
    }
    return (this.user?.name?.length ?? 0) > 2 ? ((this.user?.name?.[0] ?? '') + (this.user?.name?.[1] ?? '')) : ((this.user?.email?.[0] ?? '') + (this.user?.email?.[1] ?? ''))
  }

  goToLogin() {
    this.auth.loginWithRedirect({
      openUrl: (url) => {
        this.session.flushUser();
        window.location.replace(url);
      },
      appState: {
        redirect: window.location.origin
      }
    });    
  }
}
