import { Injectable } from '@angular/core';
import { createClient, Entry } from 'contentful';
import { from, map} from 'rxjs';
import { environment } from '../../../../environments/environment';


@Injectable({
    providedIn: 'root'
  })
  export class ContentfulService {
    private client = createClient({
        space: environment.contentful.spaceId,
        accessToken: environment.contentful.token


    });
    constructor(
        ) { }


    getHeroEntries(query?: object): Promise<Entry<any>[]> {
        return this.client.getEntries(Object.assign({
            content_type: 'homePageHero'
        }, query))
            .then(res => res.items);
    }

    getHero() {
        const query = {
          content_type: 'homePageHero',
        };
        const data = this.client.getEntries(query)

        return from(data).pipe(map(response => response.items.map(item => item.fields)))
    }

    getDesignToolHome() {
        const query = {
            content_type: 'designToolProducts',
          };
          const data = this.client.getEntries(query)
  
          return from(data).pipe(map(response => response.items.map(item => item.fields)))
    }
      
    getPopularCategoriesHome() {
        const query = {
          content_type: 'popularCategoriesHome',
        };
        const data = this.client.getEntries(query)

        return from(data).pipe(map(response => response.items.map(item => item.fields)),map(mappedArray => mappedArray.shift())) // takes first index
    }

    getPopularProductsHome() {
        const query = {
          content_type: 'popularProductsHome',
        };
        const data = this.client.getEntries(query)

        return from(data).pipe(map(response => response.items.map(item => item.fields)))
    }

    getFeaturedCollections() {
        const query = {
            content_type: 'featuredCollections',
          };
          const data = this.client.getEntries(query)
  
          return from(data).pipe(map(response => response.items.map(item => item.fields)),map(mappedArray => mappedArray.shift())) // takes first index
    }

    getTopBrands() {
        const query = {
          content_type: 'topBrands',
        };
        const data = this.client.getEntries(query)

        return from(data).pipe(map(response => response.items.map(item => item.fields)),map(mappedArray => mappedArray.shift())) // takes first index
    }

    getSubcategories(query?: object): Promise<Entry<any>[]> {
        return this.client.getEntries(Object.assign({
            content_type: 'subcategories'
        }, query))
            .then(res => res.items);
    }


    getSubcategory() {
        const query = {
          content_type: 'subcategories',
        };
        const data = this.client.getEntries(query)

        return from(data).pipe(map(response => response.items.map(item => item.fields)))
    }

    getProductSection1() {
        const query = {
          content_type: 'frequentlyBoughtTogetherProducts',
        };
        const data = this.client.getEntries(query)

        return from(data).pipe(map(response => response.items.map(item => item.fields)))
    }

    getProductSection2() {
        const query = {
          content_type: 'relatedItems',
        };
        const data = this.client.getEntries(query)

        return from(data).pipe(map(response => response.items.map(item => item.fields)))
    }

    getProductSection3() {
        const query = {
          content_type: 'popularItems',
        };
        const data = this.client.getEntries(query)

        return from(data).pipe(map(response => response.items.map(item => item.fields)))
    }

    getImageCarousel() {
        const query = {
          content_type: 'loginImageCarousel',
        };
        const data = this.client.getEntries(query)

        return from(data).pipe(map(response => response.items.map(item => item.fields)),map(mappedArray => mappedArray.shift())) // takes first index
    }
    
    getBanner(){
        const query = {
            content_type: 'banner',
          };
          const data = this.client.getEntries(query)
  
          return from(data).pipe(map(response => response.items.map(item => item.fields)))
    }


    // **
    // Disabled for later

    getRetiringCollections(query?: object): Promise<Entry<any>[]> {
        return this.client.getEntries(Object.assign({
            content_type: 'retiringCollections'
        }, query))
            .then(res => res.items);
    }

    getTodaysDeals(query?: object): Promise<Entry<any>[]> {
        return this.client.getEntries(Object.assign({
            content_type: 'todaysDeals'
        }, query))
            .then(res => res.items);
    }


    getCatalog(catalogId): Promise<Entry<any>> {
        return this.client.getEntries(Object.assign({
            content_type: 'catalog'
        }, {'sys.id': catalogId}))
            .then(res => res.items[0]);
    }
     // **
    // Disabled for later


    // 2025 Homepage
    getCarouselHeader() {
        const query = {
          content_type: 'carouselHeader',
        };
        const data = this.client.getEntries(query)
        return from(data).pipe(map(response => response.items.map(item => item.fields)))
    }

    getCategoryGallery() {
        const query = {
          content_type: 'categoryGallery',
        };
        const data = this.client.getEntries(query)
        return from(data).pipe(map(response => response.items.map(item => item.fields)))
    }

    getKeyFeatures() {
        const query = {
          content_type: 'keyFeatures',
        };
        const data = this.client.getEntries(query)
        return from(data).pipe(map(response => response.items.map(item => item.fields)))
    }

    getCallToAction() {
        const query = {
          content_type: 'callToAction',
        };
        const data = this.client.getEntries(query)
        return from(data).pipe(map(response => response.items.map(item => item.fields)))
    }

    getFAQ() {
        const query = {
          content_type: 'faq',
        };
        const data = this.client.getEntries(query)
        return from(data).pipe(map(response => response.items.map(item => item.fields)))
    }
  }