export const environment = {
    production: true,
    baseUrl: 'https://cp.culturestudio.net/',
    affiliatePlatformRootUrl: 'https://cp.stokkup.com/',
    apiUrl: 'https://dp.culturestudio.net/api/public/client-portal/',
    apiUrl2: 'https://platform.stokkup.com/',
    apiRoot: 'https://dp.culturestudio.net/api/public/',
    apiAWS:  'https://api.stokkup.com/graphql',
    apiMicroservice: 'https://api.culturestudio.app/',
    easyPostKey: 'EZAK1e87d20f88f843bcbedaa6b09ae9d7d7gnt1CSBon4yYXtkiy8iuZg',
    recaptchaKey: '6Lf-BhooAAAAAFstsfCPbWhvLwgGV5ET9ep0mv0e',
    staticMapsKey: 'AIzaSyCMbmGp-ExpiLEW9CgwkJ7Jx4GVJf2mkl8',
    apiAWSKey: 'da2-6vf3srxrrnfqfkxwg5hajk7yia',
    cloudMersiveKey: '8258c3bf-d427-402d-8b7d-27b053462272',
    mapbox: {
        accessToken: 'pk.eyJ1IjoiY3VsdHVyZXN0dWRpbyIsImEiOiJjbDYzdzc1cnoybDJ5M2Jtb21yeGJzODZ5In0.ZPojUJQrohoyFGaBejR3dw'
    },
    contentful: {
      spaceId: 'w3t9vxb15tqc',
      token: 'bYgCViV2p8Q1X0sNBbqSNcvrX-AU8nq51g6y79oEQq8',
    },
    googlePlacesKey:'AIzaSyAliGmtZw6f0NgoXdzyX8z23aFjAe3NT4M',
    auth0: {
      domain: 'auth.culturestudio.net',
      clientId: 'AuvfWc9W6pEZ4UI9k9NK2NxmOiKfxtIS',
      audience: 'https://api.culturestudio.app'
    },
    DATADOG_API_KEY: '88ce07b2e7773664fa4ea71af3aee13f'
};
