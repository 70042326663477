import { Component, OnInit } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { SessionService } from '@core/services/session/session.service';

@Component({
  selector: 'signup',
  template: '',
})
export class SignupComponent implements OnInit{
  constructor(
    protected auth: AuthService,
    private session: SessionService
  ) { }
  
  ngOnInit(): void {
    this.auth.loginWithRedirect({
      authorizationParams: {
        screen_hint: "signup" as any, // TypeScript workaround
      },
      openUrl: (url) => {
        this.session.flushUser();
        window.location.replace(url);
      },
      appState: {
        redirect: window.location.origin
      }
    });
  }
}
